import { ChangeEventHandler } from 'react';
import Select from 'react-select'


export function MyLoginTextField(props: { name: string, label: string, type: any, onChange: ChangeEventHandler, value?: string }) {

  const { name, label, type, onChange, value } = props;

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', margin: '15px' }}>
        <label style={{ fontSize: '20px' }}>{label}</label>
        <input
          name={name}
          type={type}
          onChange={onChange}
          value={value}
          style={{
            fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
            padding: '3px 10px', backgroundColor: '#D9D9D9', color: "#333333", border: 'none', borderRadius: '5px', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
          }} />
      </div>
    </>
  );
}


export function MyTextField(props: { label: string, name?: string, type: any, defaultValue?: any, size?: any, pattern?: any, placeholder?: any, disabled?: any, value?: any, onChange?: ChangeEventHandler, customStyle?:any }) {

  const { label, type, name, defaultValue, size, pattern, placeholder, disabled, onChange, value, customStyle } = props;

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', margin: '10px' }}>
        <label style={{ fontSize: '16px', textAlign: 'center' }}>{label}</label>
        <input
          type={type}
          pattern={pattern}
          value={value}
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          style={
            customStyle != null ? customStyle : 
            {
            height: size?.height != null ? size?.height : '3vh',
            padding: '3px 10px',
            backgroundColor: '#D9D9D9',
            opacity: disabled === true ? 0.5 : 1,
            color: "#000",
            border: 'none',
            borderRadius: '5px',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
            cursor: disabled === true ? 'not-allowed' : 'text',
          }}
        />
      </div>
    </>
  );
}

export function MyTextFieldSelect(props: { label: string, name?: string, defaultValue?: any, size?: any, placeholder?: any, disabled?: any, value?: any, onChange?: ChangeEventHandler, options: any, customStyle?: any }) {

  const { label, name, defaultValue, size, placeholder, disabled, onChange, value, options, customStyle } = props;

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', margin: '10px' }}>
        <label style={{ fontSize: '16px', textAlign: 'center' }}>{label}</label>
        <select
          value={value}
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}

          style={
            customStyle != null ? customStyle :
              {
                height: size?.height != null ? size?.height : '3vh',
                padding: '3px 10px',
                backgroundColor: '#C9C9C9',
                opacity: disabled === true ? 0.5 : 1,
                color: "#000",
                border: 'none',
                borderRadius: '5px',
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                cursor: disabled === true ? 'not-allowed' : 'text',
              }
          }
        >
          <option key={name + "1"} value="" selected disabled hidden></option>
          {options.map((option: any) => (
            <option key={option.name} value={option.name}>{option.name}</option>
          ))}
        </select>
      </div>
    </>
  );
}

export function MySelectTextField(props: { label: string, options: any, size?: any, placeholder?: any }) {

  const { label, size, options, placeholder } = props;

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', margin: '10px' }}>
        <label style={{ fontSize: '16px', marginLeft: '10px' }}>{label}</label>
        <Select
          options={options}
          placeholder={placeholder}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: '#C9C9C9',
              color: 'black',
              border: 'none',
              boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
              width: size?.width != null ? size?.width : '63vh',
              height: size?.height != null ? size?.height : '4vh',
            }),
          }}
        />
      </div>
    </>
  );
}