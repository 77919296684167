import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import FlagIcon from '@mui/icons-material/Flag';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PanoramaVerticalSelectIcon from '@mui/icons-material/PanoramaVerticalSelect';
import PersonIcon from '@mui/icons-material/Person';
import { Grid, IconButton, List, ListItemText, Tooltip, Box, Typography } from "@mui/material";
import { MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import SelectedStatus from 'MyComponents/Status/SelectedStatus';
import { MyTextField } from "MyComponents/TextField/MyTextField";
import MyModalSubTitle from "MyComponents/Title/MyModalSubTitle";
import { IMessage } from 'pages/Clients/type/Message';
import { useEffect, useRef, useState } from 'react';
import CanvasDraw from "react-canvas-draw";
import { useTranslation } from "react-i18next";
import { CustomModals } from 'shared/modals/customModals';
import { IDeliveryCreator } from '../type/Delivery/DeliveryCreator';
import { IDeliveryData, IDeliveryStep } from '../type/Delivery/DeliveryData';
import DeliveryStepSignature from "./DeliveryStepSignature";

export function ViewDelivery(props: { open: any, refresh: any, setOpen: any, delivery: any }) {

    const { t } = useTranslation();
    const { open, refresh, setOpen, delivery } = props;

    const [inputs, setInputs] = useState<IDeliveryCreator>({});
    const [deliverySteps, setdeliverySteps] = useState<Array<IDeliveryData>>([]);
    const [selectedDeliveryStep, setSelectedDeliveryStep] = useState<IDeliveryStep>();
    const [openStepSignature, setOpenStepSignature] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState<IMessage>({ type: 'error', content: '' });

    const canvasRefFrom = useRef<any>(null);
    const canvasRefTo = useRef<any>(null);

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        if (delivery?.deliverySteps != null) {
            let sortedSteps: Array<IDeliveryStep> = delivery.deliverySteps.sort((n1: IDeliveryStep, n2: IDeliveryStep) => {
                if (n1.step != null && n2.step != null) {
                    if (n1.step > n2.step) return 1;
                    if (n1.step < n2.step) return -1;
                }
                return 0;
            });
            setdeliverySteps(sortedSteps)
        }

    }, [delivery]);

    const handleChangeDelivery = (event: any) => {
        setInputs({
            ...inputs,
            [event.target.name]: event.target.value

        })
    }
    const handleOpenSignature = (selectedStep: IDeliveryStep) => {
        setSelectedDeliveryStep(selectedStep);
        setOpenStepSignature(true);
    }

    useEffect(() => {
        if (delivery?.signatureFrom != null) {
            canvasRefFrom?.current?.loadSaveData(delivery?.signatureFrom, true)
        }

        if (delivery?.signatureTo != null) {
            canvasRefTo?.current?.loadSaveData(delivery?.signatureTo, true)
        }
    }, [delivery])

    const actions =
        <>
            <MyOutlinedButton onClick={handleClose}>{t("cancel")}</MyOutlinedButton>
        </>

    const content =
        <>
            {delivery != null && delivery != undefined ?
                (<>
                    <MyModalSubTitle>
                        <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                        Status
                    </MyModalSubTitle>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SelectedStatus delivery={delivery} />
                        </Grid>
                    </Grid>
                    <MyModalSubTitle>
                        <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                        {t("informations")}
                    </MyModalSubTitle>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        {/* Bloc Signatures */}
                        <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                            <Box textAlign="center" flex={1}>
                                <Typography>{t("signatureFrom")}</Typography>
                                {delivery?.signatureFrom ? (
                                    <CanvasDraw
                                        style={{ margin: 'auto' }}
                                        canvasWidth={250}
                                        canvasHeight={250}
                                        disabled
                                        ref={canvasRefFrom}
                                        immediateLoading={true}
                                        saveData={delivery?.signatureFrom}
                                    />
                                ) : (
                                    <Typography>No signature yet</Typography>
                                )}
                                <MyTextField type='text' disabled name="remark" label={t("remarkSignatureFrom")} defaultValue={delivery?.signatureFromRemark} onChange={handleChangeDelivery} />
                            </Box>
                            <Box textAlign="center" flex={1}>
                                <Typography>{t("signatureTo")}</Typography>
                                {delivery?.signatureTo ? (
                                    <CanvasDraw
                                        style={{ margin: 'auto' }}
                                        canvasWidth={250}
                                        canvasHeight={250}
                                        disabled
                                        ref={canvasRefTo}
                                        immediateLoading={true}
                                        saveData={delivery?.signatureTo}
                                    />
                                ) : (
                                    <Typography>No signature yet</Typography>
                                )}
                                <MyTextField type='text' disabled name="remark" label={t("remarkSignatureTo")} defaultValue={delivery?.signatureToRemark} onChange={handleChangeDelivery} />
                            </Box>
                        </Box>

                        {/* Bloc Informations complémentaires */}
                        <Box display="flex" justifyContent="space-between" gap="1rem" flex={1} flexWrap="wrap">
                            <Box flex={1}>
                                <MyTextField disabled type='text' name="type" label={t("typeOfDelivery")} defaultValue={delivery?.type?.name} onChange={handleChangeDelivery} />
                            </Box>
                            <Box flex={1}>
                                <MyTextField disabled type='text' name="carrierId" label={t("carrier")} defaultValue={delivery?.carrier ? `${delivery.carrier.user.firstname} ${delivery.carrier.user.lastname}` : ""} onChange={handleChangeDelivery} />
                            </Box>
                            <Box flex={1}>
                                <MyTextField disabled type='text' name="clientId" label="Client" defaultValue={"[ " + delivery?.client?.company + " ] " +  delivery?.client?.user?.firstname + " " + delivery?.client?.user?.lastname} onChange={handleChangeDelivery} />
                            </Box>
                        </Box>
                    </div>
                    <MyModalSubTitle>
                        <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                        {t("journey")}
                    </MyModalSubTitle>
                    <Grid container spacing={1} columns={{ xs: 4, md: 12 }}>
                        <Grid item xs={1}>
                            <EmojiFlagsIcon sx={{ color: '#009CFF', fontSize: '40px', marginTop: '10px' }} />
                        </Grid>
                        <Grid item xs={11}>
                            <MyTextField disabled={true} type='text' name="favAddressFromId" label={t("addressFrom")} defaultValue={
                                delivery?.addressFrom?.address?.street + " " +
                                delivery?.addressFrom?.address?.city + " " +
                                delivery?.addressFrom?.address?.complement}
                            />
                        </Grid>
                        {deliverySteps != null &&
                            <Grid item xs={12}>
                                <List
                                    sx={{
                                        overflow: 'auto',
                                        maxHeight: 150,
                                        '& ul': { padding: 0 },
                                    }}
                                    subheader={<li />}>
                                    {deliverySteps.map((deliveryStep: IDeliveryStep) => {
                                        return (
                                            <li key={deliveryStep.step}>
                                                <ul>
                                                    <ListItemText >
                                                        <Grid container spacing={1} columns={{ xs: 7, md: 12 }}>
                                                            <Grid item xs={1}>
                                                                <Tooltip title={(deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} placement='top'>
                                                                    <PanoramaVerticalSelectIcon sx={{ color: '#009CFF', fontSize: '25px', marginTop: '30px' }} />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <MyTextField
                                                                    disabled={true}
                                                                    type='text'
                                                                    name="favAddressId"
                                                                    value={deliveryStep?.address?.address?.street + " " + deliveryStep?.address?.address?.city + " " + deliveryStep?.address?.address?.country}
                                                                    label={t("stepAddresses") + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <MyTextField
                                                                    disabled={true}
                                                                    type='text'
                                                                    name="clientId"
                                                                    value={deliveryStep?.address?.fullname}
                                                                    label={t("receiverSTep") + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Tooltip title={(deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} placement='top'>
                                                                    <PanoramaVerticalSelectIcon sx={{ color: '#009CFF', fontSize: '25px', marginTop: '30px' }} />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <MyTextField
                                                                    disabled={true}
                                                                    type='text'
                                                                    name="remark"
                                                                    value={deliveryStep?.remark}
                                                                    label={t("remarkStep") + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Tooltip title={t("displaySignature")} placement='top'>
                                                                    <IconButton
                                                                        aria-label="close"
                                                                        color="inherit"
                                                                        size="small"
                                                                        disabled={deliveryStep.signatureTo == null ? true : false}
                                                                        onClick={() => handleOpenSignature(deliveryStep)}>
                                                                        {
                                                                            deliveryStep.signatureTo == null ?
                                                                                <AssignmentLateIcon sx={{ color: '#717476', fontSize: '45px' }} />
                                                                                :
                                                                                <AssignmentTurnedInIcon sx={{ color: '#77a56b', fontSize: '45px' }} />
                                                                        }
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemText>
                                                </ul>
                                               
                                            </li>
                                        )
                                    })}
                                </List>
                            </Grid>
                        }
                        <Grid item xs={1}>
                            <FlagIcon sx={{ color: '#009CFF', fontSize: '40px', marginTop: '10px' }} />
                        </Grid>
                        <Grid item xs={11}>
                            <MyTextField disabled={true} type='text' name="favAddressToId" label={t("addressTo")} defaultValue={
                                delivery?.addressTo?.address?.street + " " +
                                delivery?.addressTo?.address?.city + " " +
                                delivery?.addressTo?.address?.complement}
                            />
                        </Grid>
                        {selectedDeliveryStep &&
                            <DeliveryStepSignature
                                deliveryStep={selectedDeliveryStep}
                                open={openStepSignature}
                                setOpen={setOpenStepSignature}
                            />
                        }
                    </Grid>
                </>
                ) : (<></>)
            }
        </>

    return (
        <CustomModals
            open={open}
            handleClose={handleClose}
            title={t("view") + " " + t("delivery")}
            content={content}
            actions={actions}
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            message={message}
            setMessage={setMessage}
            notContent={true}
        />
    )
}
