import { Grid } from '@material-ui/core';
import { MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { IDeliveryData } from 'pages/Delivery/type/Delivery/DeliveryData';
import { IInvoiceCreator, IItemCreator } from 'pages/Invoice/type/invoiceCreator';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { DateYmdToDmY } from 'shared/utils/localeISODateTime';


export function SelectAmount(props: {
    deliveries: Array<IDeliveryData>
    inputs: IInvoiceCreator | undefined,
    setInputs: Function,
    setCalculate: Function,
    setTitle: Function,
    setIsDueDate: Function,
}) {
    const {
        deliveries,
        inputs,
        setInputs,
        setCalculate,
        setTitle,
        setIsDueDate,
    } = props;

    const { t } = useTranslation();


    const [options, setOptions] = useState<Array<string>>([])
    const [items, setItems] = useState<Array<IItemCreator>>([]);

    const endRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        if (endRef.current != null) {
            endRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    const handleChangeDueDate = (e: any) => {
        setInputs({
            [e.target.name]: e.target.value,
            ...inputs
        })
        setIsDueDate(true);
    }

    const handleAddStep = (event: any, elementIndex: number) => {
        let oldElement: IItemCreator | null | undefined = inputs?.items?.at(elementIndex);

        let delivery = deliveries.find(({ id }) => id === oldElement?.deliveryId);

        let newElement: IItemCreator = {
            amount: 0,
            description: "Transport: " + DateYmdToDmY(delivery?.datetime),
            quantity: 1,
            tva: 0,
            total: 0,
            deliveryId: oldElement?.deliveryId
        }

        if (inputs?.items != null) {
            //let index = inputs?.items.findIndex(item => item.deliveryId == oldElement?.deliveryId);
            let lastDeliveryItem: IItemCreator | null | undefined = inputs?.items.filter((item: IItemCreator) => item.deliveryId === oldElement?.deliveryId)
                                                .slice(-1)[0];
            let index = inputs?.items.indexOf(lastDeliveryItem);
            inputs?.items?.splice(index + 1, 0, newElement);

            setInputs({ ...inputs });
            scrollToBottom();
        }
    }

    const handleChangeInvoiceItem = (event: any, elementIndex: number) => {
        let oldElement: IItemCreator | null | undefined = inputs?.items?.at(elementIndex);
        if (oldElement != null) {
            switch (event.target.name) {
                case "description":
                    oldElement.description = event.target.value;
                    break;
                case "tva":
                    setCalculate(false)
                    oldElement.tva = +event.target.value;
                    break;
                case "amount":
                    setCalculate(false)
                    oldElement.amount = +event.target.value;
                    break;
                default:
                    break;
            }

            //Calculate total
            let tvaPrice = (
                (
                    (oldElement?.tva != null ? oldElement?.tva : 0) *
                    (oldElement?.amount != null ? oldElement?.amount : 0)
                ) / 100);

            oldElement.total =
                (
                    (oldElement?.amount != null ? oldElement?.amount : 0) +
                    tvaPrice
                ) * (oldElement?.quantity != null ? oldElement?.quantity : 1)
        }
        if (inputs?.items && oldElement != null) {
            inputs?.items?.splice(elementIndex, 1);
            inputs?.items?.splice(elementIndex, 0, oldElement);
        }
        setInputs({...inputs});
    }

    useEffect(() => {
        setTitle(t("selectAmount") || "")
        let items: IItemCreator[] = [];
        let options: string[] = [""];

        deliveries.forEach(delivery => {
            let item: IItemCreator;
            item = {
                amount: 0,
                deliveryId: delivery.id,
                description: (
                    "Transport: " + DateYmdToDmY(delivery?.datetime) +
                    " N° " + delivery?.trackingNumber +
                    " Type : " + delivery?.type?.name + " " +
                    "\nFrom : " + delivery?.addressFrom?.label + " ("  + delivery?.addressFrom?.address?.zipcode + delivery?.addressFrom?.address?.city + ") " +
                    "\nTo : " + delivery?.addressTo?.label + " ("  + delivery?.addressTo?.address?.zipcode + delivery?.addressTo?.address?.city + ") " +
                    (delivery?.isComingBack ? "\nROUND TRIP" : "")
                ),
                tva: 0,
                quantity: 1,
                total: 0
            }
            items.push(item);

            if (delivery?.deliverySteps?.length) {
                delivery.deliverySteps.forEach((step, index) => items.push({
                    amount: 0,
                    description: "N° " + delivery?.trackingNumber + " Step " + (index + 1) + ": \n" + 
                                step.address?.label + " (" + step.address?.address?.zipcode + " " + step.address?.address?.city + ")",
                    quantity: 1,
                    tva: 0,
                    total: 0,
                    deliveryId: delivery?.id
                }))
            }
            
            options.push((delivery?.trackingNumber != null ? delivery.trackingNumber : ""));
        });

        setOptions(options);
        setInputs({ items: items });
        setItems(items);
    }, [])

    if (inputs?.items != null && options != null) {
        return (
            <>
                <Grid container spacing={1}>
                    {inputs.items.map((item: IItemCreator, index: number) => {
                        return (
                            <>
                                {item.deliveryId != items[index - 1]?.deliveryId &&
                                    <Grid item xs={12}>
                                        <MyModalSubTitle>
                                            Delivery Number : {deliveries.find(({ id }) => id === item.deliveryId)?.trackingNumber}
                                            <MyOutlinedButton onClick={(e) => handleAddStep(e, index)}>{t("addLine")}</MyOutlinedButton>
                                        </MyModalSubTitle>
                                    </Grid>
                                }
                                <Grid item xs={6}>
                                    <MyTextField
                                        type='text'
                                        name="description"
                                        value={item.description}
                                        label={t("Description")}
                                        onChange={(e) => handleChangeInvoiceItem(e, index)}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <MyTextField
                                        type="number"
                                        name="tva"
                                        label="TVA (%)"
                                        onChange={(e) => handleChangeInvoiceItem(e, index)}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <MyTextField
                                        type="number"
                                        name="amount"
                                        label={t("amount")}
                                        onChange={(e) => handleChangeInvoiceItem(e, index)}
                                    />
                                </Grid>
                                {inputs?.items &&
                                    <Grid item xs={2}>
                                        <MyTextField
                                            disabled
                                            type="number"
                                            name="total"
                                            label={t("total")}
                                            //defaultValue={inputs?.items[index]?.total}
                                            value={inputs?.items[index]?.total}
                                        />
                                    </Grid>
                                }
                            </>
                        )
                    })}
                    <Grid item xs={12}>
                        <MyTextField
                            type="date"
                            name="dueDate"
                            label={"Due date"}
                            value={inputs?.dueDate}
                            onChange={handleChangeDueDate}
                        />
                    </Grid>
                </Grid>
            </>
        )
    } else {
        return (<></>)
    }
}