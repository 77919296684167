import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import MyDataGrid from 'MyComponents/DataGrid/MyDataGrid';
import MyChip from 'MyComponents/Generic/MyChip';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logout } from 'shared/redux/features/auth/authSlice';
import MyTitle from '../../MyComponents/Title/MyTitle';
import { getISODate, localeISODateTime } from "../../shared/utils/localeISODateTime";
import DeliveryReduced from './DeliveryReduced';
import SearchDelivery from './SearchDelivery';
import { AttributeDelivery } from './modals/AttributeDelivery';
import { EditDelivery } from './modals/EditDelivery';
import { ViewDelivery } from './modals/ViewDelivery';
import deliveryService from './service/DeliveryService';
import deliveryTypeService from './service/DeliveryTypeService';
import { IDeliveryData } from './type/Delivery/DeliveryData';
import { IType } from './type/Type';

function Delivery(props: {
  indexNavBar: any,
  setIndexNavBar: any,
  selectedDelivery: any,
  setSelectedDelivery: any,
  setSelectedDeliveries?: any,
  open: any,
  setOpen: any,
  roles: Array<string>,
  selfId: string,
  handleHideElement: any,
  addOpen: boolean,
  setAddOpen: Function,
  editOpen?:boolean,
  setEditOpen?:Function,
  reduced: boolean
}) {

  const {
    indexNavBar,
    setIndexNavBar,
    selectedDelivery,
    setSelectedDelivery,
    setSelectedDeliveries,
    open,
    setOpen,
    roles,
    selfId,
    handleHideElement,
    addOpen,
    setAddOpen,
    editOpen,
    setEditOpen,
    reduced
  } = props;


  const ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN;
  const ROLE_CLIENT = process.env.REACT_APP_ROLE_CLIENT;
  const ROLE_CARRIER = process.env.REACT_APP_ROLE_CARRIER;
  const ROLE_INVOICE = process.env.REACT_APP_ROLE_FACTURATION;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [typeOptions, setTypeOptions] = useState<IType>();

  const [deliveries, setDeliveries] = useState<Array<IDeliveryData>>();
  const [rowCountState, setRowCountState] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(20);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });

  const [errors, setErrors] = useState();

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 897) || false;

  const handleResize = () => {
    setIsMobile(window.innerWidth < 897);
  }

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      id: false,
      destination: !isMobile,
      datetime: !isMobile,
      type: !isMobile,
      client: !isMobile,
      remark: !isMobile,
      status: !isMobile,
      delivery: isMobile,
      actions: roles.includes(ROLE_CARRIER!) && !isMobile
    });

  const [expanded, setExpanded] = useState<string | false>(false);
  const [status, setStatus] = useState("");

  const today = localeISODateTime();
  const [input, setInput] = useState({
    research: "",
    status: status,
    dateStart: getISODate(today),
    dateEnd: getISODate(today),
    type: ""
  });

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "N°",
      hideable: true,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "trackingNumber",
      headerName: "N°",
      hideable: false,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "delivery",
      headerName: t("Livraison") || "",
      flex: 4,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "left",
      renderCell: (data) => <DeliveryReduced data={data} roles={roles} handleAccept={handleAccept} expanded={expanded} setExpanded={setExpanded} />
    },
    {
      field: 'datetime',
      headerName: 'Date',
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
      flex: 1.2,
      renderCell: (data) => data.row.datetime ? (data.row.datetime).slice(0, 10) : ""
    },
    {
      field: 'type',
      headerName: 'Type',
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
      flex: 1.2,
      renderCell: (data) => data?.row?.type?.name
    },
    {
      field: t("Client"),
      headerName: roles.includes(ROLE_CLIENT!) ? t('receiver') || "" : t("Client") || "",
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
      flex: 1.2,
      renderCell: (data) => {
        if (roles.includes(ROLE_CLIENT!)) {
          return (
            data?.row?.addressTo?.fullname
          )
        } else {
          return (
            "[ " + data?.row?.client?.company + " ] " + data?.row?.client?.user?.firstname + " " + data?.row?.client?.user?.lastname
          )
        }
      }
    },
    {
      field: 'status',
      headerName: 'Statut',
      headerAlign: "center",
      type: 'number',
      headerClassName: "researchColumn",
      align: "center",
      flex: 1.2,
      renderCell: (data) => <MyChip index={(data?.row?.statuses.length) - 1} label={data?.row?.statuses[0]?.status?.name} />
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
      flex: 1,
      hideable: roles.includes(ROLE_CARRIER!),
      renderCell: (data) => {
        return (
          <Tooltip title='Accepter' placement='top'>
            <IconButton onClick={() => handleAccept(data?.row?.id, data?.row?.statuses[0]?.status?.name)}>
              <WorkHistoryIcon sx={{ color: data?.row?.statuses[0]?.status?.name === 'pending' ? '#ec952a' : 'white' }} />
            </IconButton>
          </Tooltip>
        )
      }
    },
  ];

  const handleAccept = (id: string, status: string) => {
    if (!id || status !== 'pending') return;
    deliveryService
      .acceptDelivery(id)
      .then(r => refresh().catch(err => handleError(err)))
      .catch(err => {
        if (err.statusCode === 403 || err.statusCode === 401 || err.statusCode === 400) {
          dispatch(logout());
        } else {
          setErrors(err?.response?.data?.error);
        }
      });
  }

  const resetInput = () => {
    setInput({ research: "", status: "", dateStart: "", dateEnd: "", type: "" });
  }

  const handleError = (err: any) => {
    setIsLoading(false)
    if (
      err?.statusCode === 403 ||
      err?.statusCode === 401 ||
      err?.statusCode === 400 ||
      err?.response?.status === 403 ||
      err?.response?.status === 401 ||
      err?.response?.status === 400
    ) {
      dispatch(
        logout()
      );
    } else {
      setErrors(err?.response?.data?.error)
    }
  }

  const handlePageChange = (page: any) => {
    setDefaultPageNo(page);
    setRowsState((prev) => ({ ...prev, page }));
    refresh({ pageNo: page, pageSize: defaultPageSize }).catch(err => handleError(err));
  }

  const handlePageSizeChange = (pageSize: any) => {
    setDefaultPageSize(pageSize);
    setRowsState((prev) => ({ ...prev, pageSize }));
    refresh({ pageNo: defaultPageNo, pageSize: pageSize }).catch(err => handleError(err));
  }

  const handleSelectionModelChange = (ids: any) => {
    if ((setSelectedDeliveries !== undefined) && (ids.length > 0)) { // multiple selection mode
      setSelectionModel(ids);
      setSelectedDeliveries(deliveries?.filter((delivery: any) => ids?.includes(delivery?.id)));
    } else { // single selection mode
      setSelectionModel(ids[ids.length - 1]);
      deliveries?.forEach((delivery: any) => {
        if (ids[ids.length - 1] === delivery.id) {
          setSelectedDelivery(delivery)
        }
      });
    }
  }


  const refresh = (
    { pageNo, pageSize } = {
      pageNo: rowsState.page,
      pageSize: rowsState.pageSize,
    }
  ) => {
    setIsLoading(true)
    if (roles.includes(ROLE_ADMIN!) && !reduced) {
      return deliveryService.getAllDeliveries(pageNo, pageSize, input).then(
        (response: any) => {
          const data = response.data.content?.map((row: IDeliveryData) => {
            const { deliveryId, ...rest } = row;
            return { id: deliveryId, ...rest };
          });
          setDeliveries(data);
          if (setSelectedDeliveries !== undefined) {
            setSelectedDeliveries();
          }
          setSelectedDelivery(null)
          setIsLoading(false)
          setRowCountState(response.data.totalElements);
        }
      ).catch((err) => handleError(err));
    } else if (roles.includes(ROLE_CARRIER!)) {
      return deliveryService.getAllDeliveriesCarrier(pageNo, pageSize, selfId, input).then(
        (response: any) => {
          const data = response.data.content;
          setDeliveries(
            data.map((row: IDeliveryData) => {
              const { deliveryId, ...rest } = row;
              return { id: deliveryId, ...rest };
            })
          );
          setSelectedDelivery(null)
          setIsLoading(false)
          setRowCountState(response.data.totalElements);
        }
      ).catch((err) => handleError(err));
    } else {
      return deliveryService.getAllDeliveriesClient(pageNo, pageSize, selfId, input, (roles.includes(ROLE_INVOICE!) ? false : true)).then(
        (response: any) => {
          const data = response.data.content;
          setDeliveries(
            data.map((row: IDeliveryData) => {
              const { deliveryId, ...rest } = row;
              return { id: deliveryId, ...rest };
            })
          );
          setSelectedDelivery(null)
          setIsLoading(false)
          setRowCountState(response.data.totalElements);
        }
      ).catch((err) => handleError(err));
    }

  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleHideElement(false);
    setIndexNavBar(indexNavBar); //set color of current page button on navbar
    setSelectedDelivery(null); //reset selected delivery
    deliveryTypeService.getDeliveryType(defaultPageNo, defaultPageSize)
      .then(r => {
        setTypeOptions(r.data.content)
      }).catch(err => handleError(err));
    refresh({
      pageNo: defaultPageNo,
      pageSize: defaultPageSize
    }).catch(err => handleError(err));
  }, []);

  useEffect(() => {
    if (roles.includes(ROLE_ADMIN!)) {
      if (reduced) setStatus("shipped");
      return setStatus("created");
    } else if (roles.includes(ROLE_CARRIER!)) {
      return setStatus("pending");
    }
    return setStatus("");
  })

  useEffect(() => {
    const timeoutId = setTimeout(
      () => refresh({
        pageNo: defaultPageNo,
        pageSize: defaultPageSize
      }).catch(err => handleError(err)),
      300
    );
    return () => clearTimeout(timeoutId);
  }, [input])


  useEffect(() => {
    setColumnVisibilityModel({
      id: false,
      destination: !isMobile,
      datetime: !isMobile,
      type: !isMobile,
      client: !isMobile,
      remark: !isMobile,
      status: !isMobile,
      delivery: isMobile,
      actions: roles.includes(ROLE_CARRIER!) && !isMobile
    })
  }, [isMobile]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 86.5px)', width: '100%' }}>
      {!reduced &&
        <MyTitle>
          {t("navDelivery")}
        </MyTitle>
      }
      <SearchDelivery
        input={input}
        expanded={expanded}
        setInput={setInput}
        isMobile={isMobile}
        resetInput={resetInput}
        setExpanded={setExpanded}
        typeOptions={typeOptions}
      />
      <MyDataGrid
        errors={errors}
        columns={columns}
        rows={deliveries}
        rowsState={rowsState}
        isLoading={isLoading}
        rowCountState={rowCountState}
        selectionModel={selectionModel}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        handleSelectionModelChange={handleSelectionModelChange}
      />
      <AttributeDelivery
        delivery={selectedDelivery}
        type={"une livraison"}
        open={addOpen}
        refresh={refresh}
        setOpen={setAddOpen}
      />
      <ViewDelivery
        open={open}
        refresh={refresh}
        setOpen={setOpen}
        delivery={selectedDelivery}
      />

      <EditDelivery
        open={editOpen}
        refresh={refresh}
        setOpen={setEditOpen}
        delivery={selectedDelivery}
      />
    </div>
  );
}

export default Delivery;