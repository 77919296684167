import DownloadIcon from '@mui/icons-material/Download';
import { IconButton, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import MyDataGrid from 'MyComponents/DataGrid/MyDataGrid';
import { MyAccordion } from 'MyComponents/Generic/MyAccordion';
import { IDeliveryData, IDeliveryStep } from 'pages/Delivery/type/Delivery/DeliveryData';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { logout } from 'shared/redux/features/auth/authSlice';
import { DateYmdToDmY, getISODate, localeISODateTime } from 'shared/utils/localeISODateTime';
import { IPayload } from 'types/payload';
import SearchInvoice from './SearchInvoice';
import { AddInvoice } from './modals/AddInvoice';
import { EditInvoice } from './modals/EditInvoice';
import { SendInvoice } from './modals/SendInvoice';
import invoiceService from './service/InvoiceService';
import { IDeliveryStepUpdate, IInvoiceMultipleUpdate } from './type/InvoiceMultipleUpdate';
import { IInvoice } from './type/invoice';
export interface IInput {
  research: string;
  startDate: string;
  endDate: string;
  status: string;

}

function Invoice(props: {
  indexNavBar: any,
  setIndexNavBar: any,
  setSelectedInvoice: any,
  selectedInvoice: any,
  setInvoiceToShow: any,
  open: any,
  setOpen: any,
  handleHideElement: any,
  roles: Array<String>,
  payload: IPayload
}) {

  const {
    indexNavBar,
    setIndexNavBar,
    setSelectedInvoice,
    selectedInvoice,
    setInvoiceToShow,
    open,
    setOpen,
    handleHideElement,
    roles,
    payload
  } = props;

  const ROLE_CLIENT = process.env.REACT_APP_ROLE_CLIENT;

  const [canExport, setCanExport] = useState(true);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [input, setInput] = useState<IInput>({
    research: "",
    startDate: new Date().toISOString().slice(0, 10),
    endDate: "",
    status: ""
  }
  );

  const [openEmail, setOpenEmail] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openPdfPreview, setOpenPdfPreview] = useState(false);

  const [selectedInvoices, setSelectedInvoices] = useState<Array<IInvoice>>();

  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState<Array<IInvoice>>();
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<Array<String>>();
  const [rowCountState, setRowCountState] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 10,
  });

  const [editInvoiceInputs, setEditInvoiceInputs] = useState<IInvoiceMultipleUpdate>({});
  const [deliverySteps, setDeliverySteps] = useState<Array<IDeliveryStepUpdate>>();
  const [delivery, setDelivery] = useState<IDeliveryData | null>();

  const [errors, setErrors] = useState(null);

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 897) || false;

  const handleResize = () => {
    setIsMobile(window.innerWidth < 897);
  }

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      id: false,
      datetime: !isMobile,
      client: !isMobile,
      amount: !isMobile,
      invoice: isMobile,
      preview: true,
    });

  const [expanded, setExpanded] = useState<string | false>(false);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      hideable: true,
      flex: 0.5,
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
    },
    {
      field: "issueDate",
      headerName: "Issue Date",
      flex: 1,
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 1,
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
    },
    {
      field: "fullname",
      headerName: t('fullName') || "",
      flex: 1,
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
    },
    {
      field: 'amount',
      headerName: t('amount') + ' (€)',
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
      flex: 1,
    },
    {
      field: "invoice",
      headerName: t("Facture") || "",
      flex: 4,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "left",
      renderCell: (data) => {
        return (
          <MyAccordion
            id={`${data.row?.id}`}
            summary={`${data.row?.items[0]?.delivery?.client?.user?.lastname || ''} ${data.row?.items[0]?.delivery?.client?.user?.firstname || ''} [${data.row?.items[0]?.delivery?.client?.phone || ''}]`}
            description={`${DateYmdToDmY(data.row.issueDate)}: ${data.row?.items[0]?.delivery?.type?.name} ${data.row?.amount ? data.row?.amount + "€" : ""}`}
            content={
              <>
                <Typography sx={{ fontSize: 13, fontWeight: 700 }}>{t("Start")}:</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.items[0]?.delivery?.addressFrom?.address?.complement || ''}`}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.items[0]?.delivery?.addressFrom?.address?.street || ''}`}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.items[0]?.delivery?.addressFrom?.address?.zipcode || ''} ${data.row?.delivery?.items[0].addressFrom?.address?.city || ''}`}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.items[0]?.delivery?.addressFrom?.address?.country || ''}`}</Typography>
                <Typography sx={{ fontSize: 13, fontWeight: 700 }}>{t("End")}:</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.items[0]?.delivery?.addressTo?.address?.complement || ''}`}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.items[0]?.delivery?.addressTo?.address?.street || ''}`}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.items[0]?.delivery?.addressTo?.address?.zipcode || ''} ${data.row?.items[0]?.delivery?.addressTo?.address?.city || ''}`}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.items[0]?.delivery?.addressTo?.address?.country || ''}`}</Typography>

              </>}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        )
      }
    },
    {
      field: 'preview',
      headerName: t('download') || "",
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
      flex: 0.5,
      renderCell: (data) => {
        return (
          <Tooltip title={t("displayPreview")} placement='top'>
            <IconButton id={data.row.id}
              onClick={(e) => {
                e.preventDefault();
                let selected = invoices?.filter((invoice: any) => invoice?.id === data.row.id)[0];

                let ds: Array<IDeliveryStepUpdate> = [];
                if (selected?.items != null) {
                  selected?.items[0]?.delivery?.deliverySteps?.forEach((deliveryStep: IDeliveryStep) =>
                    ds.push(
                      {
                        deliveryStepId: deliveryStep.deliveryStepId,
                        amount: deliveryStep.amount
                      }
                    )
                  )
                  setDeliverySteps(ds)
                  setEditInvoiceInputs({
                    amount: selected?.amount,
                    deliveryId: selected?.items[0]?.delivery?.deliveryId,
                    deliveryStepUpdates: ds
                  });
                }
                setSelectedInvoice(selected);
                if (selected?.items != null && selected?.items != undefined) {
                  setDelivery(selected?.items[0]?.delivery);
                }
                setInvoiceToShow(selected);

                handleExport(data.row.id);
              }}
            >
              <DownloadIcon sx={{ color: '#F1731D' }} />
            </IconButton>
          </Tooltip >
        )
      }
    },
  ];

  const handleChange = (event: any) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  }

  const resetInput = () => {
    setInput(
      {
        research: "",
        startDate: "",
        endDate: "",
        status: ""
      }
    );
  }

  const handleSelectionModelChange = (ids: any) => {
    const selectedIDs = ids;
    setSelectionModel(ids);
    setSelectedInvoiceIds(ids);
    setSelectedInvoices(invoices?.filter((invoice: any) => selectedIDs.includes(invoice.id)));

    let selected = invoices?.filter((invoice: any) => selectedIDs.includes(invoice.id))[0];
    setSelectedInvoice(selected);
    setInvoiceToShow(invoices);
  }

  const handleAddOpen = () => {
    setOpenAdd(true);
  }

  const handleError = (err: any) => {
    setIsLoading(false)
    if (
      err?.statusCode === 403 ||
      err?.statusCode === 401 ||
      err?.statusCode === 400 ||
      err?.response?.status === 403 ||
      err?.response?.status === 401 ||
      err?.response?.status === 400
    ) {
      dispatch(
        logout()
      );
    } else {
      setErrors(err?.response?.data?.error)
    }
  }

  const handleExport = (id:string) => {
    // let selectedId = id ?? selectedInvoice.id;
    invoiceService.exportInvoice(id)
      .then((byteArray:any) => {
        var pdfAsDataUri = "data:application/pdf;base64," + byteArray.data;
        var link = document.createElement('a');
        link.href = pdfAsDataUri;
        link.download = t('invoice_') + getISODate(localeISODateTime(selectedInvoice?.datetime)) + '_'+ selectedInvoice?.fullname+ '.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((err) => handleError(err));
  }

  const refresh = (
    { pageNo, pageSize } = {
      pageNo: rowsState.page,
      pageSize: rowsState.pageSize,
    }
  ) => {
    setIsLoading(true)

    let status: string = "";
    switch (input?.status) {
      case t('validated'):
        status = "false";
        break;
      case t('toValidate'):
        status = "true";
        break;
      default:
    }

    if (roles.includes(ROLE_CLIENT!)) {
      return invoiceService.getMyInvoice(pageNo, pageSize, input?.research, payload.me, input?.startDate, input?.endDate, status).then(
        (response: any) => {
          const data = response.data.content;
          setInvoices(
            data.map((row: IInvoice) => {
              const { invoiceId, ...rest } = row;
              return { id: invoiceId, ...rest };
            })
          );
          setIsLoading(false)
          setRowCountState(response.data.totalElements);
        }
      ).catch((err) => handleError(err));
    } else {
      return invoiceService.getAllInvoice(pageNo, pageSize, input?.research, input?.startDate, input?.endDate, status).then(
        (response: any) => {
          const data = response.data.content;
          setInvoices(
            data.map((row: IInvoice) => {
              const { invoiceId, ...rest } = row;
              return { id: invoiceId, ...rest };
            })
          );
          setIsLoading(false)
          setRowCountState(response.data.totalElements);
        }
        ).catch((err) => handleError(err));
    }

  };

  const handlePageChange = (page: any) => {
    setDefaultPageNo(page);
    setRowsState((prev) => ({ ...prev, page }));
      refresh({ pageNo: page, pageSize: defaultPageSize }).catch((err) => handleError(err));
  }

  const handlePageSizeChange = (pageSize: any) => {
    setDefaultPageSize(pageSize);
    setRowsState((prev) => ({ ...prev, pageSize }));
    refresh({ pageNo: defaultPageNo, pageSize: pageSize }).catch((err) => handleError(err));
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleHideElement(false);
    setIndexNavBar(indexNavBar); //set color of current page button on navbar
    setInvoiceToShow(null); // reset PDF preview
    setSelectedInvoice(null);
    refresh({
      pageNo: defaultPageNo,
      pageSize: defaultPageSize
    }).catch((err) => handleError(err));
  }, []);

  useEffect(() => {
    if (selectedInvoice?.length === 0) {
      setCanExport(true);
    } else {
      setCanExport(false);
    }
  }, [selectedInvoice]);

  useEffect(() => {
    const timeoutId = setTimeout(
      () => refresh({
        pageNo: defaultPageNo,
        pageSize: defaultPageSize,

      }).catch((err) => handleError(err)),
      500
    );
    return () => clearTimeout(timeoutId);
  }, [input])

  useEffect(() => {
    setColumnVisibilityModel({
      id: false,
      datetime: !isMobile,
      client: !isMobile,
      amount: !isMobile,
      invoice: isMobile,
      preview: true,
    })
  }, [isMobile]);


  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 86.5px)', width: '100%' }}>
      <SearchInvoice
        isMobile={isMobile}
        input={input}
        handleChange={handleChange}
        // handleExport={handleExport}
        canExport={canExport}
        resetInput={resetInput}
        setExpanded={setExpanded}
        expanded={expanded}
        roles={roles}
        handleAddOpen={handleAddOpen}
      />

      <AddInvoice
        open={openAdd}
        setOpen={setOpenAdd}
        refresh={refresh}
        indexNavBar={indexNavBar}
        setIndexNavBar={setIndexNavBar}
        handleHideElement={handleHideElement}
        payload={payload}
        roles={roles}
      />

      {selectedInvoices != null &&
        <SendInvoice
          open={openEmail}
          setOpen={setOpenEmail}
          ids={selectedInvoiceIds}
          selectedInvoices={selectedInvoices}
          refresh={refresh}
        />
      }

      {selectedInvoice?.items != null &&
        <EditInvoice
          inputs={editInvoiceInputs}
          setInputs={setEditInvoiceInputs}
          deliverySteps={deliverySteps}
          setDeliverySteps={setDeliverySteps}
          open={open}
          setOpen={setOpen}
          refresh={refresh}
          invoiceItems={selectedInvoice?.items}
          invoice={selectedInvoice}
          setInvoiceToShow={setInvoiceToShow}
        />
      }

      <MyDataGrid
        errors={errors}
        columns={columns}
        rows={invoices}
        rowsState={rowsState}
        isLoading={isLoading}
        rowCountState={rowCountState}
        selectionModel={selectionModel}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        handleSelectionModelChange={handleSelectionModelChange}
      />
    </div>
  );
}

export default Invoice;
