import { useRef, useState } from 'react';
import { IMessage } from 'pages/Clients/type/Message';
import deliveryService from '../service/DeliveryService';
import { logout } from 'shared/redux/features/auth/authSlice';
import { IDeliveryData, IDeliveryStep } from '../type/Delivery/DeliveryData';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import Grid from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch } from 'react-redux';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import CanvasDraw from "react-canvas-draw";
import { IDeliveryCreator } from '../type/Delivery/DeliveryCreator';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from '@mui/material/Collapse';
import { Alert, Box } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { StatusPage } from './StatusPage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useTranslation } from "react-i18next";
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { IDeliveryStepUpdate } from '../type/Delivery/IDeliveryStepUpdate';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function DeliveryStep(props: {
    setOpen: Function,
    open: boolean,
    handleHideElement: Function,
    delivery: IDeliveryData,
    refreshDelivery: Function,
    errors: IMessage,
    activeStep: number,
    openAlert: boolean,
    setOpenAlert: Function,
    setActiveStep: Function,
    setErrors: Function,
    handleClose: Function
}) {
    const {
        open,
        setOpen,
        handleHideElement,
        delivery,
        refreshDelivery,
        errors,
        activeStep,
        openAlert,
        setOpenAlert,
        setActiveStep,
        setErrors,
        handleClose
    } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [signatureTo, setSignatureTo] = useState();
    const [remark, setRemark] = useState(null);
    const [selectedDeliveryStep, setSelectedDeliveryStep] = useState<IDeliveryStep | null>();

    const canvasRef = useRef<any>(null)
    
    const handleRemarkchange = (event: any) => {
        setRemark(event.target.value);
    }
     
    const handleAccept = () => {
        setOpenAlert(true);
        if (!delivery || !delivery.deliveryId || !selectedDeliveryStep || !selectedDeliveryStep.deliveryStepId) return;

        const deliveryStep: IDeliveryStepUpdate = { signatureTo, remark };
        
        deliveryService.validateDeliverySteps(delivery.deliveryId, selectedDeliveryStep.deliveryStepId, deliveryStep)
            .then(r => {
                setErrors({ type: 'success', content: t("validate") });
                setOpenAlert(false);
                refreshDelivery(r.data)
                setTimeout(handleClose, 1500);
                setActiveStep(5);
            })
            .catch(err => {
                if (err.statusCode === 403 || err.statusCode === 401 || err.statusCode === 400) {
                    dispatch(logout());
                } else {
                    setErrors(err?.response?.data?.error);
                }
            });
    };

    const handleChange = () => {
        const data = canvasRef?.current?.getSaveData();
        setSignatureTo(data);
    }

    if (delivery != null) {
        if (activeStep === 4) {
            return (
                <StatusPage>
                    <Box className="header" />
                    <Box className="content">
                        <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                            <MyModalSubTitle>
                                <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                {t("informations")}
                            </MyModalSubTitle>
                            <Grid container spacing={1} padding={3}>
                                <Grid item xs={12}>
                                    <MyTextField disabled={true} type='text' name="clientId" label="Client" defaultValue={delivery?.client?.user?.firstname + " " + delivery?.client?.user?.lastname} />
                                </Grid>
                                <Grid item xs={6}>
                                    <MyTextField disabled={true} type='text' name="type" label={t("typeOfDelivery")} defaultValue={delivery?.type?.name} />
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <MyTextField disabled={true} type='text' name="remark" label={t("remark")} defaultValue={delivery?.remark} />
                                </Grid> */}
                            </Grid>
                            <MyModalSubTitle>
                                <LocalShippingIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                {t("journey")}
                            </MyModalSubTitle>
                            <Grid container spacing={1} padding={3}>
                                <Grid item xs={12}>
                                    <MyTextField disabled={true} type='text' name="favAddressFromId" label={t("addressFrom")} defaultValue={
                                        delivery?.addressFrom?.address?.street + " " +
                                        delivery?.addressFrom?.address?.city + " " +
                                        (delivery?.addressFrom?.address?.complement ? delivery.addressFrom.address.complement : '')}

                                    />
                                </Grid>
                                {delivery?.deliverySteps?.sort((a: any, b: any) => a?.step - b?.step)?.map((step: any) => 
                                    <>
                                        <Grid item xs={11}>
                                            <MyTextField disabled={true} type='text' name="favAddressFromId" label={t("shippingSteps") + ` ${step.step + 1} : ${step?.address?.fullname}`} defaultValue={
                                                step?.address?.address?.street + " " +
                                                `${step?.address?.address?.zipcode} ${step?.address?.address?.city} ` +
                                                (step?.address?.address?.complement ? step.address.address.complement : "")
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={1} display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'} marginBottom={1}>
                                            {step?.signatureTo
                                                ? <CheckBoxIcon sx={{color: 'green', fontSize: '40px'}} /> 
                                                : <CheckBoxOutlineBlankIcon sx={{color: 'silver', fontSize: '40px'}} />
                                            }
                                        </Grid>
                                    </>
                                    )
                                }
                                <Grid item xs={12}>
                                    <MyTextField disabled={true} type='text' name="favAddressToId" label={t("addressTo")} defaultValue={
                                        delivery?.addressTo?.address?.street + " " +
                                        delivery?.addressTo?.address?.city + " " +
                                        (delivery?.addressTo?.address?.complement ? delivery.addressTo.address.complement : '')}
                                    />
                                </Grid>
                            </Grid>
                            <div>
                                <MyContainedButton onClick={() => setActiveStep(5)}>{t("next>")}</MyContainedButton>
                            </div>
                        </Box>
                    </Box>
                </StatusPage>
            )
        } else if (activeStep === 5) {
            return (
                <>
                    <StatusPage>
                        <Box className="header" />
                        <Box className="content">
                            <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                                <MyModalSubTitle>
                                    <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                    {t("Client")}
                                </MyModalSubTitle>
                                <Grid container spacing={1} padding={3}>
                                    <Grid item xs={12}>
                                        <MyTextField disabled={true} type='text' name="clientId" label="Client" defaultValue={delivery?.client?.user?.firstname + " " + delivery?.client?.user?.lastname} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <List
                                        sx={{
                                            overflow: 'auto',
                                            maxHeight: '80vh',
                                            '& ul': { padding: 0 },
                                        }}
                                        subheader={<li />}>
                                        {delivery?.deliverySteps?.map((deliveryStep: IDeliveryStep) => {
                                            return (
                                                <li key={deliveryStep.step}>
                                                    <ul>
                                                        <ListItemText >
                                                            <Grid container spacing={1} padding={3} columns={{ xs: 6, md: 12 }}>
                                                                <Grid item xs={6}>
                                                                    <MyTextField
                                                                        disabled={true}
                                                                        type='text'
                                                                        name="favAddressId"
                                                                        value={deliveryStep?.address?.address?.street + " " +  deliveryStep?.address?.address?.city + " " +  deliveryStep?.address?.address?.country}
                                                                        label={t("stepAddresses") + deliveryStep.step} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <MyTextField
                                                                        disabled={true}
                                                                        type='text'
                                                                        name="clientId"
                                                                        value={deliveryStep?.address?.fullname}
                                                                        label={t("receiverSTep") + deliveryStep.step} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <IconButton
                                                                        aria-label="close"
                                                                        color="inherit"
                                                                        size="small"
                                                                        disabled={ deliveryStep.signatureTo == null ? false : true}
                                                                        onClick={() => {
                                                                            setActiveStep(6)
                                                                            setSelectedDeliveryStep(deliveryStep)
                                                                        }}>
                                                                        {
                                                                            deliveryStep.signatureTo == null ?
                                                                                <AssignmentLateIcon sx={{ color: '#009CFF', fontSize: '45px' }} />
                                                                                :
                                                                                <AssignmentTurnedInIcon sx={{ color: '#77a56b', fontSize: '45px' }} />
                                                                        }
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItemText>
                                                    </ul>
                                                </li>
                                            )
                                        })}
                                    </List>
                                </Grid>
                                 <div>
                                    <MyOutlinedButton onClick={() => setActiveStep(4)}>{t("<back")}</MyOutlinedButton>
                                </div>
                            </Box>
                        </Box>
                    </StatusPage>
                </>
            );
        } else if (activeStep === 6) {
            return (<>
                <StatusPage>
                    <Box className="header" />
                    <Box className="content">
                        <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                            <Grid container spacing={1} padding={3}>
                                <Grid item xs={12}>
                                    <MyModalSubTitle>
                                        <AssignmentLateIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                        {t("receiverSTep") + " " + selectedDeliveryStep?.step}
                                    </MyModalSubTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <MyModalSubTitle>
                                        <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                        {t("signature") + " " + selectedDeliveryStep?.address?.fullname}
                                    </MyModalSubTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <CanvasDraw
                                            canvasWidth={300}
                                            canvasHeight={250}
                                            ref={canvasRef}
                                            loadTimeOffset={10}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <MyTextField type='text' name='remark' value={remark} label='remark' onChange={handleRemarkchange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Collapse in={openAlert}>
                                        {(errors?.type) &&
                                            <Alert severity={'info'}
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() => {
                                                            setOpenAlert(false);
                                                            setErrors({ type: 'error', content: '' });
                                                        }}
                                                    >
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                }
                                            >
                                                {errors?.content || ''}
                                            </Alert>
                                        }
                                    </Collapse>
                                </Grid>
                            </Grid>
                            <div>
                                <MyOutlinedButton onClick={() => setActiveStep(5)}>{t("<back")}</MyOutlinedButton>
                                <MyContainedButton onClick={handleAccept}>{t("validate")}</MyContainedButton>
                            </div>
                        </Box>
                    </Box>
                </StatusPage>
            </>)
        } else {
            return (
                <>
                    <StatusPage>
                        <Box className="header" />
                        <Box className="content">
                            <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                                <MyModalSubTitle>{t("deliveryNotFound")}</MyModalSubTitle>
                            </Box>
                        </Box>
                    </StatusPage>
                </>
            )
        }
    } else {
        return (
            <>
                <StatusPage>
                    <Box className="header" />
                    <Box className="content">
                        <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                            <MyModalSubTitle>{t("deliveryNotFound")}</MyModalSubTitle>
                        </Box>
                    </Box>
                </StatusPage>
            </>
        )
    }

};

export default DeliveryStep;